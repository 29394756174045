@import "../src/assets/theme/index.scss";

body {
  font-family: "Josefin Sans", sans-serif;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $secondry;
  border-radius: 10px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #7035e5 inset !important;
  -webkit-text-fill-color: #fff;

  fieldset {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
figure,
ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

li {
  padding-left: 0;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

a {
  text-decoration: none;
}

.conta_iner {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.d_blck {
  display: block;
}
fieldset {
  display: none;
}
@keyframes slide {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.btn_wrp {
  background: white;
  width: max-content;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  .cmn_btn {
    min-width: 170px;
    padding: 12px;
    font-size: 20px;
    border-radius: 5px;
    color: #fff;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    z-index: 12;
    border: 0;
    background: transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      background: white !important;
      color: black !important;
    }

    &:before {
      content: "";
      top: 0;
      transform: translateX(100%);
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: -1;
      animation: slide 2s infinite;
      background: linear-gradient(
        to right,
        rgba(64, 235, 241, 0) 0%,
        rgba(255, 254, 253, 56%) 50%,
        rgba(255, 254, 253, 28%) 99%,
        rgba(64, 235, 241, 0) 100%
      );
    }
  }
  &:after {
    content: "";
    background: #120a2b;
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.btn_wrp:hover:after {
  transform: translate3d(0, -105%, 0);
}
// .cmn_btn:hover:before {
//   transform: translate3d(0, 105%, 0);
// }
.hd_r {
  background: $primary;
  padding: 26px 0px 10px;

  .conta_iner.hdr_2 {
    display: none;
  }

  .mn_hdr {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hdr_lft {
      width: 40%;

      figure {
        height: 60px;
        width: 200px;
        cursor: pointer;
      }
    }

    .hdr_ryt {
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      ul {
        display: flex;
        width: 70%;
        justify-content: space-between;
        align-items: center;

        li {
          cursor: pointer;

          h6 {
            font-size: 16px;
            font-weight: 500;
            color: $white;
          }
        }
      }
    }
  }
}

.ft_r {
  background-color: $secondry;
  color: $white;

  .mn_ftr {
    padding: 70px 0;

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: stretch;

      li.ftr_sec {
        width: 30%;

        .logo {
          height: 60px;
          width: 280px;
          position: relative;
          top: -20px;
        }

        p {
          font-size: 18px;
          margin: 10px 0;
          line-height: 26px;
          font-weight: 400;

          cursor: auto;
        }

        .scl {
          margin-top: 30px;

          .scl_icns {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            figure {
              width: 30px;
              height: 30px;
              margin-right: 14px;
              cursor: pointer;
            }
          }
        }
      }

      li {
        width: 14%;
        position: relative;
        transition: 0.3s linear;

        p {
          font-size: 16px;
          font-weight: 400;
          margin: 20px 0;
          color: white;
          cursor: pointer;
        }

        h4 {
          font-size: 14px;
        }
      }
    }
  }

  .btm_ftr {
    background: #231f2d;
    text-align: center;
    padding: 16px 0;

    p {
      font-size: 16px;
    }
  }
}

.hm_bnr {
  background-image: url("../public/static/images/bnr_img.svg");
  height: 640px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  position: relative;

  // overflow: hidden;
  .mn_bnr {
    display: flex;
    justify-content: flex-start;

    .bnr_lft {
      width: 50%;
      padding-top: 110px;
      color: $white;

      h1 {
        font-size: 50px;
        font-weight: 500;
        line-height: 56px;

        .wlt {
          font-weight: 500;
          color: #ffd500;
        }

        span {
          font-weight: 300;
        }
      }

      p {
        font-size: 20px;
        line-height: 28px;
        margin: 20px 0;
      }

      button {
        //   background: $secondry;
        //   color: $white;
        //   border: none;
        padding: 16px 26px;
        border-radius: 6px;
        text-transform: uppercase;
        font-size: 12px;
        //   // margin-top: 40px;
        cursor: pointer;
      }
    }

    .bnr_ryt {
      width: 50%;

      figure {
        height: 550px;
        width: 550px;
        position: absolute;
        bottom: -45px;
      }
    }
  }
}

.crypto_wlt {
  padding: 100px 0;
  overflow: hidden;

  .mn_wlt {
    display: flex;
    justify-content: space-between;

    .crypto_lft {
      width: 50%;

      figure {
        height: 462px;
        width: 552px;
      }
    }

    .crypto_ryt {
      width: 50%;

      h2 {
        font-size: 34px;
        line-height: 44px;
        color: $text;
        font-weight: bold;
        position: relative;
      }

      h2::after {
        content: "";
        position: absolute;
        background-image: url("../public/static/images/coin.png");
        height: 80px;
        width: 80px;
        right: -50px;
        top: -70px;
        background-size: contain;
        background-repeat: no-repeat;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin: 14px 0;
        color: $text;
      }

      h4 {
        font-size: 22px;
        font-weight: 500;
        color: $text;
      }
    }
  }
}

.crypto_bnfts {
  padding-top: 100px;
  overflow: hidden;

  .bnfts {
    h2 {
      font-size: 34px;
      line-height: 38px;
      color: $text;
      font-weight: bold;
      text-align: center;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin: 14px 0;
      color: $text;
      text-align: center;
    }

    .mn_bnfts {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 20px;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 24%;

        figure {
          height: 80px;
          width: 80px;
          margin-bottom: 30px;
        }

        .lst_brdr {
          border: 1px dashed #707070;
          width: 80%;
          position: absolute;
          left: 243px;
          top: 128px;
        }

        .lst_brdr:after {
          content: "";
          position: absolute;
          right: -4px;
          width: 15px;
          height: 15px;
          background: $primary;
          top: -7px;
          border-radius: 50%;
        }

        .lst_brdr:before {
          content: "";
          position: absolute;
          left: -4px;
          width: 15px;
          height: 15px;
          background: $primary;
          top: -7px;
          border-radius: 50%;
        }
      }
    }
  }
}

.crpto_tlrd {
  padding-top: 100px;
  overflow: hidden;

  .tlrd_mn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .tlrd_lft {
      width: 50%;

      h2 {
        font-size: 34px;
        line-height: 38px;
        color: $text;
        font-weight: bold;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin: 14px 0;
        color: $text;
      }

      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        figure {
          width: 150px;
          height: 100px;
          margin-right: 30px;
          cursor: pointer;
        }
      }
    }

    .tlrd_ryt {
      width: 45%;

      figure {
        height: 610px;
        width: 500px;
      }
    }
  }
}

.why_blcktnx {
  padding: 100px 0;
  overflow: hidden;

  .mn_blctnx {
    .blktnx_wlt {
      h2 {
        font-size: 34px;
        line-height: 38px;
        color: $text;
        font-weight: bold;
        text-align: center;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin: 14px 0;
        color: $text;
        text-align: center;
      }
    }

    .blktnx_ftrs {
      padding-top: 40px;
      overflow: hidden;

      .ftrs_crd {
        border: 1px solid #70707024;
        background: #f8fdff;
        border-radius: 10px;
        padding: 40px 42px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 40px 40px #00000008;

        figure {
          height: 60px;
          width: 60px;
        }

        p {
          font-size: 22px;
          padding: 20px 0;
          text-align: center;
          color: $text;
        }
      }
      .ftrs_crd:hover {
        background-color: #cbecf9;
      }

      .ftrs_crd_change {
        background-color: #fff8f8;
      }
      .ftrs_crd_change:hover {
        background-color: #f7bebe;
      }
    }
  }
}

.strt_erng {
  padding: 60px 0;
  background: $primary;
  overflow: hidden;

  .mn_erng {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 60px;
      line-height: 60px;
      color: $white;
      font-weight: bold;
      text-align: center;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin: 14px 0;
      color: $white;
      text-align: center;
    }
    .btn_wrp {
      margin-top: 16px;
      button {
        cursor: pointer;
      }
    }

    .cnct_frm {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      border: 2px solid $white;
      border-radius: 23px;
      padding: 35px 41px;
      margin-top: 40px;

      .cnct_lft {
        width: 50%;

        h4 {
          font-size: 46px;
          font-weight: 700;
          line-height: 47px;
          text-align: left;
          color: $white;

          span {
            font-size: 30px;
          }
        }

        p {
          font-size: 16px;
          text-align: left;
        }
        figure {
          width: 280px;
          margin: 20px auto;
          margin-left: 13%;
        }
        ul {
          position: relative;

          li {
            position: relative;
            padding-left: 42px;
            &:first-child {
              padding-bottom: 22px;
              ::before {
                content: "";
                content: "";
                position: absolute;
                top: 30px;
                border-left: 1px dashed #fff;
                height: 100%;
                left: 13px;
              }
            }
            &::before {
              position: absolute;
              content: "";
              height: 20px;
              width: 20px;
              border: 4px solid #fff;
              background-color: #46219c;
              border-radius: 50%;
              top: 3px;
              left: 0;
            }
            p {
              text-align: left;
            }
          }
        }
      }

      .cnct_ryt {
        width: 50%;
        h4 {
          font-size: 30px;
          font-weight: 900;
          color: $white;
        }
        p {
          font-size: 16px;
          text-align: left;
        }

        .react-tel-input .form-control {
          border: 1px solid $white;
          border-radius: 6px;
          height: 60px;
          width: 100%;
          font-size: 14px;
          font-weight: 500px;
        }
        .react-tel-input .selected-flag:hover,
        .react-tel-input .selected-flag:focus {
          background-color: transparent;
        }
        .react-tel-input .flag-dropdown {
          background-color: transparent;
          border: none;
        }
        .inpt_fld input::placeholder {
          color: white;
          font-size: 14px;
          font-weight: 500;
          opacity: 1;
        }
        .inpt_fld input {
          color: white;
          border: 1px solid $white;
          font-size: 14px;
          font-weight: 500;
          border-radius: 6px;
        }
        .inpt_fld input:focus {
          box-shadow: 0 0 1pt 1.5pt rgb(255, 255, 255);
        }
        .msg_fld textarea:focus {
          box-shadow: 0 0 1pt 2pt rgb(255, 255, 255);
        }
        .inpt_fld input:hover {
          border: 1px solid $white !important;
          color: $white !important;
        }
        .react-tel-input .country-list {
          width: 400px;
        }
        .react-tel-input .flag-dropdown.open .selected-flag {
          background-color: transparent;
        }
        input.search-box {
          width: 350px !important;
          color: $text !important;
        }
        input.search-box::placeholder {
          color: gray !important;
        }
        input.search-box:focus {
          border: 1px solid gray;
        }
        input.search-box:hover {
          width: 350px !important;
          color: $text !important;
          border: 1px solid gray !important;
        }

        .msg_fld div {
          color: white;
          font-size: 14px;
          font-weight: 500;
          border: 1px solid $white;
          padding: 0;
        }
        .msg_fld textarea {
          padding: 14px;
        }

        .msg_fld div textarea::placeholder {
          color: white !important;
          opacity: 1;
          font-size: 14px;
          font-weight: 500;
        }
        p#\:r0\:-helper-text {
          color: red !important;
          font-size: 14px;
          margin: 0;
        }
        p#\:r1\:-helper-text {
          color: red !important;
          font-size: 14px;
          margin: 0;
        }
        p#\:r2\:-helper-text {
          color: red !important;
          font-size: 14px;
          margin: 0;
        }
        p#\:r3\:-helper-text {
          color: red !important;
          font-size: 14px;
          margin: 0;
        }
        p.err_msg {
          color: red !important;
          font-size: 15px;
          padding-top: 2px;
          margin: 0;
        }
        .err_inpt input {
          border: 1px solid red !important;
          border-radius: 5px;
          color: $white;
        }
        .err_inpt input::placeholder {
          color: white !important;
          opacity: 1;
        }

        .sbmt {
          margin: 30px auto 0;
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.mn_crpto {
  position: relative;
  padding-bottom: 260px;
  overflow: hidden;

  .pplr_crpto {
    padding: 60px 0 270px;
    background-color: $primary;

    h2 {
      font-size: 34px;
      line-height: 38px;
      color: $white;
      font-weight: bold;
      text-align: center;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin: 14px 0;
      color: $white;
      text-align: center;
    }
  }
}

.crpto_tdy {
  position: absolute;
  top: 160px;
  left: 0px;
  right: 0px;

  .tbl_cll {
    border-bottom: 1px solid $secondry;
    font-weight: 500;
    font-size: 16px;
  }

  .srl_no {
    color: $lighttext;
  }

  .cntnt_nm {
    color: $lighttext;

    .cntnt_shrtnm {
      color: rgb(0 0 0 / 40%);
    }
  }

  .tbl_bld {
    font-weight: 600;
  }

  .ng_chng {
    color: #ff0000;
  }

  .pstv_chng {
    color: #1d8d1d;
  }
}

section.blctnx_rtng {
  padding-top: 100px;
  padding-bottom: 170px;
  background: #b1e3ff2b;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    background-image: url("../public/static/images/purple_circle.svg");
    height: 100px;

    width: 100px;
    background-size: 100%;
    background-repeat: no-repeat;
    left: 10px;
    top: 25px;
  }
  &::after {
    content: "";
    position: absolute;
    background-image: url("../public/static/images/peach_circle.png");
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-size: 100%;
    left: 30px;
    bottom: 70px;
  }

  .mn_rtng {
    display: flex !important;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .rtng_lft {
      width: 40%;
      position: absolute;
      right: 0;
      &::before {
        content: "";
        position: absolute;
        background-image: url("../public/static/images/green_circle.svg");
        height: 60px;
        width: 60px;
        background-size: 100%;
        background-repeat: no-repeat;
        right: 40px;
        top: -85px;
      }

      &::after {
        content: "";
        position: absolute;
        background-image: url("../public//static/images/green_circle.svg");
        height: 40px;
        width: 40px;
        background-size: 100%;
        background-repeat: no-repeat;
        bottom: -10px;
        right: 30px;
      }

      figure {
        height: 280px;
        width: 260px !important;
        transform: scale(0.85);
        transform-origin: top;
        transition: 0.4s ease-in-out;

        img {
          object-fit: cover;
          border-radius: 20px;
        }
      }

      .slick-current {
        height: 300px !important;

        figure {
          height: 330px !important;
        }

        img {
          object-fit: cover;
        }
      }

      .arrow_btns {
        display: flex;
        justify-content: flex-start;
        padding: 20px;

        div {
          margin-left: 30px;
          border: 1px solid black;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .rtng_ryt {
      width: 50%;

      figure {
        height: 70px;
        width: 70px;
        position: relative;
      }

      .rtng_str {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        figure {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }

      h2 {
        font-size: 24px;
        line-height: 30px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          background-image: url("../public/static/images/peach_circle.png");
          height: 40px;
          width: 40px;
          background-size: 100%;
          background-repeat: no-repeat;
          left: 330px;
          top: -50px;
        }
      }

      p.user_des {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        margin-top: 0px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
        margin-top: 20px;
        font-weight: 400;
        color: $lighttext;
      }
    }
  }
}

.conta_iner.pri_plcy {
  padding: 70px 0px 70px;

  h1 {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 40px;
    color: $secondry;
  }

  p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: $secondry;
    margin-bottom: 16px;
  }

  h4 {
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    color: $secondry;
  }

  ul {
    li {
      position: relative;

      ::before {
        content: "";
        position: absolute;
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: black;
        top: 10px;
        left: 0;
      }

      p {
        margin-left: 20px;
      }
    }
  }
}

.conta_iner.trms_use {
  padding: 70px 0px 70px;

  h1 {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 40px;
    color: $secondry;
  }

  p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: $secondry;
    margin-bottom: 16px;
  }

  h4 {
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    color: $secondry;
  }
}

// -----------------------------------------Responsive---------------------------------------
@media screen and (max-width: 1920px) {
  .hd_r {
    padding: 30px 0px 10px;

    .mn_hdr {
      .hdr_lft figure {
        height: 80px;
        width: 270px;
      }

      .hdr_ryt {
        width: 45%;

        ul li h6 {
          font-size: 22px;
        }
      }
    }
  }

  .hm_bnr {
    height: 860px;

    .mn_bnr {
      .bnr_lft {
        padding-top: 170px;

        h1 {
          font-size: 60px;
          font-weight: 600;
          line-height: 76px;
        }

        p {
          font-size: 24px;
          line-height: 36px;
        }

        button {
          font-size: 18px;
          padding: 22px 46px;
        }
      }

      .bnr_ryt figure {
        height: 750px !important;
        width: 620px !important;
        bottom: -72px !important;
      }
    }
  }

  .crypto_wlt {
    padding: 130px 0;

    .mn_wlt {
      .crypto_lft {
        width: 45%;

        figure {
          height: 640px;
          width: 520px;
        }
      }

      .crypto_ryt {
        width: 45%;

        h2 {
          font-size: 40px;
          line-height: 56px;
        }

        h2::after {
          right: -100px;
        }

        h4 {
          font-size: 30px;
        }

        p {
          font-size: 22px;
          margin: 14px 0 26px;
          line-height: 28px;
        }
      }
    }
  }

  .mn_crpto {
    padding-bottom: 390px;

    .pplr_crpto {
      h2 {
        font-size: 40px;
        line-height: 56px;
      }

      p {
        font-size: 22px;
        // margin: 14px 0 26px;
        line-height: 28px;
      }
    }
  }

  .crpto_tdy {
    top: 200px;

    .tbl_cll,
    .srl_no {
      font-size: 20px;
    }

    .cntnt_nm,
    .tbl_bld,
    .ng_chng,
    .pstv_chng {
      font-size: 18px;
    }
  }

  .crypto_bnfts {
    padding-top: 100px;

    .bnfts {
      h2 {
        font-size: 40px;
        line-height: 56px;
      }

      p {
        font-size: 22px;

        line-height: 28px;
      }

      .mn_bnfts {
        li {
          width: 30%;

          figure {
            height: 100px;
            width: 100px;
          }

          .lst_brdr {
            width: 50%;

            left: 290px;
            top: 156px;
          }
        }
      }
    }
  }

  .crpto_tlrd {
    .tlrd_mn {
      .tlrd_lft {
        width: 42%;

        h2 {
          font-size: 40px;
          line-height: 56px;
        }

        p {
          font-size: 22px;

          line-height: 28px;
        }

        div {
          figure {
            width: 210px;
            height: 120px;
          }
        }
      }
    }
  }

  .why_blcktnx {
    .mn_blctnx {
      .blktnx_wlt {
        h2 {
          font-size: 40px;
          line-height: 56px;
        }

        p {
          font-size: 22px;

          line-height: 28px;
        }
      }

      .blktnx_ftrs .ftrs_crd {
        padding: 40px 40px 50px;

        p {
          font-size: 28px;
          line-height: 40px;
          font-weight: 500;
          padding: 26px 0;
        }

        figure {
          height: 90px;
          width: 90px;
        }
      }
    }
  }

  section.blctnx_rtng {
    padding-bottom: 230px;

    .mn_rtng {
      .rtng_ryt {
        figure {
          height: 90px;
          width: 90px;
        }

        h2 {
          font-size: 32px;
          line-height: 40px;
        }

        p.user_des {
          font-size: 18px;
          line-height: 28px;
        }

        .rtng_str {
          margin-top: 10px;

          figure {
            height: 26px;
            width: 26px;
          }
        }

        p {
          font-size: 22px;
          line-height: 30px;
          margin-top: 24px;
        }
      }

      .rtng_lft {
        .slick-list {
          height: 400px;

          figure {
            height: 380px;
            width: 100% !important;
          }

          .slick-current {
            // height: 440px !important;
            figure {
              width: 100% !important;
              height: 440px !important;
            }
          }
        }

        .arrow_btns {
          div {
            width: 50px;
            height: 50px;
            margin-left: 70px;
          }
        }
      }
    }
  }

  .strt_erng .mn_erng {
    h2 {
      font-size: 80px;
      line-height: 80px;
    }

    p {
      font-size: 24px;
      line-height: 30px;
    }

    .btn_wrp {
      margin-top: 20px;
      button {
        font-size: 18px;
        padding: 16px 28px;
        border-radius: 6px;
      }
    }
  }

  .ft_r {
    .mn_ftr {
      ul {
        li.ftr_sec {
          .logo {
            height: 80px;
            width: 300px;
          }

          p {
            font-size: 22px;
            margin: 10px 0;
            line-height: 30px;
          }
        }

        li {
          h4 {
            font-size: 20px;
            font-weight: 600;
          }

          p {
            font-size: 20px;
            margin: 28px 0;
          }
        }
      }
    }

    .btm_ftr {
      padding: 20px 0;

      p {
        font-size: 20px;
      }
    }
  }
  .conta_iner.trms_use {
    h1 {
      font-size: 50px;
    }
    p {
      font-size: 26px;
      line-height: 38px;
    }
    h4 {
      font-size: 32px;

      line-height: 62px;
    }
  }

  .conta_iner.pri_plcy {
    h1 {
      font-size: 50px;
    }
    p {
      font-size: 26px;
      line-height: 38px;
    }
    h4 {
      font-size: 32px;

      line-height: 62px;
    }
  }
}

@media screen and (max-width: 1700px) {
  .hd_r {
    padding: 30px 0px 10px;

    .mn_hdr {
      .hdr_lft {
        figure {
          height: 60px;
          width: 250px;
        }
      }

      .hdr_ryt {
        ul {
          li {
            h6 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .hm_bnr {
    height: 760px;

    .mn_bnr {
      .bnr_lft {
        padding-top: 160px;

        h1 {
          font-size: 50px;
          line-height: 63px;
          font-weight: 500;
        }

        p {
          font-size: 20px;
          line-height: 30px;
        }

        button {
          font-size: 14px;
          padding: 18px 30px;
        }
      }

      .bnr_ryt figure {
        height: 630px !important;
        width: 540px !important;
      }
    }
  }

  .crypto_wlt {
    .mn_wlt {
      .crypto_lft {
        figure {
          height: 488px;
          width: 470px;
        }
      }

      .crypto_ryt {
        h2 {
          font-size: 36px;
          line-height: 50px;
        }

        h4 {
          font-size: 26px;
        }

        p {
          font-size: 18px;
          margin: 10px 0 20px;
          line-height: 26px;
        }
      }
    }
  }

  .mn_crpto {
    padding-bottom: 323px;

    .pplr_crpto {
      h2 {
        font-size: 36px;
        line-height: 50px;
      }

      p {
        font-size: 18px;

        line-height: 26px;
      }
    }
  }

  .crpto_tdy {
    top: 180px;

    .tbl_cll,
    .srl_no {
      font-size: 18px;
    }

    .cntnt_nm,
    .tbl_bld,
    .ng_chng,
    .pstv_chng {
      font-size: 16px;
    }

    // button {
    //   padding: 12px 20px;
    //   font-size: 14px;
    // }
  }

  .crypto_bnfts {
    padding-top: 100px;

    .bnfts {
      h2 {
        font-size: 36px;
        line-height: 50px;
      }

      p {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .crpto_tlrd {
    .tlrd_mn {
      .tlrd_lft {
        width: 38%;

        h2 {
          font-size: 36px;
          line-height: 50px;
        }

        p {
          font-size: 18px;
          line-height: 26px;
        }

        div {
          figure {
            width: 190px;
            height: 110px;
          }
        }
      }

      .tlrd_ryt {
        figure {
          height: 570px;
          width: 470px;
        }
      }
    }
  }

  .why_blcktnx {
    .mn_blctnx {
      .blktnx_wlt {
        h2 {
          font-size: 36px;
          line-height: 50px;
        }

        p {
          font-size: 18px;
          line-height: 26px;
        }
      }

      .blktnx_ftrs .ftrs_crd {
        p {
          font-size: 26px;
          line-height: 38px;
        }
      }
    }
  }

  section.blctnx_rtng {
    .mn_rtng {
      .rtng_ryt {
        figure {
          height: 70px;
          width: 70px;
        }

        h2 {
          font-size: 30px;
          line-height: 38px;
        }

        p.user_des {
          font-size: 16px;
          line-height: 20px;
        }

        .rtng_str {
          margin-top: 8px;

          figure {
            height: 22px;
            width: 22px;
          }
        }

        p {
          font-size: 18px;
          line-height: 28px;
          margin-top: 24px;
        }
      }

      .rtng_lft {
        .slick-list {
          height: 340px;

          figure {
            height: 330px;
          }

          .slick-current {
            height: 300px !important;

            figure {
              height: 380px !important;
            }
          }
        }

        .arrow_btns {
          div {
            width: 40px;
            height: 40px;
            margin-left: 60px;
          }
        }
      }
    }
  }

  .strt_erng {
    padding: 59px 0;

    .mn_erng {
      h2 {
        font-size: 65px;
        line-height: 70px;
      }

      p {
        font-size: 20px;
        line-height: 30px;
      }

      .btn_wrp {
        button {
          font-size: 14px;
          padding: 18px 30px;
        }
      }
    }
  }

  .ft_r {
    .mn_ftr {
      ul {
        li.ftr_sec {
          .logo {
            height: 70px;
            width: 250px;
          }

          p {
            font-size: 18px;
            margin: 10px 0;
            line-height: 28px;
          }
        }

        li {
          h4 {
            font-size: 16px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }

    .btm_ftr {
      p {
        font-size: 16px;
      }
    }
  }
  .conta_iner.trms_use {
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 20px;
      line-height: 30px;
    }
    h4 {
      font-size: 26px;
      line-height: 40px;
    }
  }
  .conta_iner.pri_plcy {
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 20px;
      line-height: 30px;
    }
    h4 {
      font-size: 26px;
      line-height: 40px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .hd_r .mn_hdr {
    .hdr_lft {
      figure {
        height: 48px;
        width: 230px;
      }
    }

    .hdr_ryt ul li h6 {
      font-size: 16px;
    }
  }

  .hm_bnr {
    height: 670px;

    .mn_bnr {
      .bnr_lft {
        padding-top: 160px;

        h1 {
          font-size: 48px;
          line-height: 55px;
        }

        p {
          font-size: 18px;
          line-height: 28px;
          margin: 10px 0;
        }
        .btn_wrp {
          margin-top: 60px;
          button {
            font-size: 12px;
            padding: 16px 30px;
          }
        }
      }

      .bnr_ryt figure {
        height: 600px !important;
        width: 500px !important;
        right: 170px;
        bottom: -52px !important;
      }
    }
  }

  .crypto_wlt {
    padding: 110px 0;

    .mn_wlt {
      .crypto_lft {
        figure {
          height: 468px;
          width: 450px;
        }
      }

      .crypto_ryt {
        h2 {
          font-size: 34px;
          line-height: 48px;
        }

        h2::after {
          height: 60px;
          width: 60px;
        }

        h4 {
          font-size: 24px;
          font-weight: 400;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }

  .mn_crpto {
    padding-bottom: 302px;

    .pplr_crpto {
      h2 {
        font-size: 38px;
        line-height: 48px;
      }
    }
  }

  .crpto_tdy {
    .tbl_cll,
    .srl_no {
      font-size: 16px;
    }

    .cntnt_nm,
    .tbl_bld,
    .ng_chng,
    .pstv_chng {
      font-size: 14px;
    }

    // button {
    //   padding: 10px 20px;
    // }
  }

  .crypto_bnfts {
    .bnfts {
      h2 {
        font-size: 34px;
        line-height: 48px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .crpto_tlrd {
    .tlrd_mn {
      .tlrd_lft {
        width: 37%;

        h2 {
          font-size: 34px;
          line-height: 48px;
        }

        p {
          font-size: 18px;
        }

        div {
          figure {
            width: 184px;
            height: 120px;
          }
        }
      }

      .tlrd_ryt {
        figure {
          height: 530px;
          width: 470px;
        }
      }
    }
  }

  .why_blcktnx {
    .mn_blctnx {
      .blktnx_wlt {
        h2 {
          font-size: 34px;
          line-height: 48px;
        }

        p {
          font-size: 18px;
        }
      }

      .blktnx_ftrs .ftrs_crd {
        p {
          font-size: 22px;
          line-height: 36px;
        }
      }
    }
  }

  section.blctnx_rtng {
    .mn_rtng {
      .rtng_ryt {
        width: 45%;

        h2 {
          margin-top: 10px;
        }

        p.user_des {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  .strt_erng {
    padding: 57px 0;

    .mn_erng {
      h2 {
        font-size: 60px;
        line-height: 65px;
      }

      p {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .hd_r .mn_hdr {
    .hdr_lft figure {
      height: 38px;
      width: 180px;
    }
  }

  .hm_bnr .mn_bnr .bnr_ryt figure {
    height: 560px !important;
    width: 440px !important;
  }

  .crypto_wlt .mn_wlt {
    .crypto_lft {
      figure {
        height: 478px;
        width: 500px;
      }
    }

    .crypto_ryt {
      h2 {
        font-size: 32px;
        line-height: 46px;
      }

      h2::after {
        right: 0px;
      }

      h4 {
        font-size: 22px;
        font-weight: 400;
      }
    }
  }

  .mn_crpto .pplr_crpto h2 {
    font-size: 34px;
  }

  .mn_crpto {
    padding-bottom: 292px;

    .crpto_tdy {
      .tbl_cll,
      .srl_no {
        font-size: 14px;
      }

      .cntnt_nm,
      .tbl_bld,
      .ng_chng,
      .pstv_chng {
        font-size: 12px;
      }

      button {
        font-size: 12px;
      }
    }
  }

  .crypto_bnfts .bnfts {
    h2 {
      font-size: 32px;
      line-height: 46px;
    }
  }

  .crpto_tlrd .tlrd_mn {
    .tlrd_lft {
      width: 35%;

      h2 {
        font-size: 32px;
        line-height: 46px;
      }

      div {
        figure {
          width: 172px;
          height: 100px;
        }
      }
    }

    .tlrd_ryt {
      figure {
        height: 520px;
        width: 450px;
      }
    }
  }

  .why_blcktnx .mn_blctnx {
    .blktnx_wlt {
      h2 {
        font-size: 32px;
        line-height: 44px;
      }
    }

    .blktnx_ftrs {
      .ftrs_crd {
        p {
          font-size: 21px;
          line-height: 32px;
        }
      }
    }
  }

  section.blctnx_rtng {
    padding-bottom: 200px;
    &::before {
      height: 70px;
      width: 70px;
    }

    .mn_rtng {
      .rtng_lft {
        .arrow_btns {
          div {
            width: 35px;
            height: 35px;
            margin-left: 50px;
          }
        }
      }

      .rtng_ryt {
        h2 {
          font-size: 28px;
          line-height: 32px;
        }
      }
    }
  }

  .strt_erng .mn_erng {
    h2 {
      font-size: 50px;
      line-height: 50px;
    }

    .btn_wrp {
      button {
        font-size: 12px;
        padding: 16px 20px;
        min-width: 150px;
      }
    }
  }

  .ft_r {
    .mn_ftr {
      ul {
        li.ftr_sec {
          .logo {
            height: 60px;
            width: 230px;
          }

          p {
            font-size: 16px;
            margin: 6px 0;
            line-height: 26px;
          }
        }

        li {
          h4 {
            font-size: 14px;
          }

          p {
            font-size: 14px;
            margin: 20px 0;
          }
        }
      }
    }

    .btm_ftr {
      padding: 16px 0;

      p {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .hm_bnr {
    height: 580px;

    .mn_bnr {
      .bnr_lft {
        padding-top: 100px;
      }
      .bnr_ryt figure {
        height: 530px !important;
        width: 390px !important;
      }
    }
  }

  .crypto_wlt .mn_wlt .crypto_ryt p {
    font-size: 16px;
  }

  .crypto_bnfts .bnfts p {
    font-size: 16px;
  }

  .crpto_tlrd .tlrd_mn .tlrd_lft p {
    font-size: 16px;
  }

  .why_blcktnx .mn_blctnx .blktnx_wlt p {
    font-size: 16px;
  }

  section.blctnx_rtng .mn_rtng {
    .rtng_lft {
      .slick-list {
        height: 330px;
      }

      .arrow_btns {
        div {
          margin-left: 45px;
        }
      }
    }

    .rtng_ryt p {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .conta_iner {
    max-width: 100%;
    width: 90%;
  }

  .hm_bnr {
    height: 530px;

    .mn_bnr {
      .bnr_lft {
        padding-top: 100px;

        h1 {
          font-size: 38px;
          line-height: 47px;
        }
      }

      .bnr_ryt figure {
        height: 470px !important;
        width: 320px !important;
      }
    }
  }

  .crypto_wlt {
    padding: 120px 0 70px;

    .mn_wlt {
      .crypto_ryt {
        h2 {
          font-size: 30px;
          line-height: 42px;
        }

        p {
          font-size: 15px;
        }

        h4 {
          font-size: 20px;
        }
      }

      .crypto_lft {
        figure {
          height: 468px;
          width: 390px;
        }
      }
    }
  }

  .mn_crpto .pplr_crpto {
    padding: 60px 0 274px;

    h2 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .crypto_bnfts .bnfts {
    h2 {
      font-size: 30px;
      line-height: 40px;
    }

    p {
      font-size: 15px;
    }

    .lst_brdr {
      display: none;
    }
  }

  .crpto_tlrd .tlrd_mn {
    .tlrd_lft {
      h2 {
        font-size: 30px;
        line-height: 40px;
      }

      p {
        font-size: 15px;
      }

      div {
        figure {
          width: 126px;
          height: 100px;
        }
      }
    }

    .tlrd_ryt {
      figure {
        height: 460px;
        width: 380px;
      }
    }
  }

  .why_blcktnx .mn_blctnx {
    .blktnx_wlt {
      h2 {
        font-size: 30px;
        line-height: 40px;
      }

      p {
        font-size: 15px;
      }
    }

    .blktnx_ftrs {
      .ftrs_crd {
        padding: 35px 30px 40px;

        figure {
          height: 70px;
          width: 70px;
        }
      }
    }
  }

  section.blctnx_rtng {
    padding-bottom: 140px;

    .mn_rtng {
      .rtng_ryt {
        h2 {
          font-size: 26px;
          line-height: 30px;
          &::after {
            left: 260px;
          }
        }

        .rtng_str {
          figure {
            height: 18px;
            width: 18px;
          }
        }

        p {
          font-size: 15px;
          line-height: 26px;
          margin-top: 20px;
        }
      }

      .rtng_lft {
        width: 45%;
        &::after {
          bottom: 10px;
        }

        .slick-list {
          height: 300px;

          figure {
            height: 300px;
          }

          .slick-current {
            figure {
              height: 340px !important;
            }
          }
        }

        .arrow_btns div {
          margin-left: 41px;
        }
      }
    }
  }

  .strt_erng {
    padding: 45px 0;

    .mn_erng {
      h2 {
        font-size: 45px;
        line-height: 43px;
      }

      p {
        font-size: 17px;
        line-height: 22px;
      }

      .btn_wrp {
        button {
          font-size: 12px;
          padding: 14px 28px;
          min-width: 138px;
        }
      }
      .cnct_frm .cnct_lft {
        h4 {
          font-size: 40px;
          font-weight: 700;
          line-height: 44px;
          span {
            font-size: 24px;
          }
        }
        p {
          .d_blck {
            display: inline;
          }
        }
      }
    }
  }
  .conta_iner.trms_use {
    h1 {
      font-size: 34px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
    }
    h4 {
      font-size: 24px;
    }
  }
  .conta_iner.pri_plcy {
    h1 {
      font-size: 34px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
    }
    h4 {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 992px) {
  .hdr_mnu .MuiPaper-root.MuiPaper-elevation {
    left: 0 !important;
    top: 0 !important;
    max-height: 100%;
    width: 220px !important;
    border-radius: 0 !important;
    background-color: $secondry;
  }

  .dvdr {
    background-color: $white;
  }

  .mnu_itm {
    color: $white !important;
    font-size: 16px !important;
    width: 100%;
    padding: 14px 10px !important;
  }

  .hd_r {
    padding: 20px 0px 10px;

    .conta_iner.hdr_2 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .mnu {
        width: 5%;
        margin-top: -10px;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        figure {
          width: 200px;
        }
      }
    }

    .mn_hdr {
      display: none;
    }
  }

  .hm_bnr {
    height: 402px;

    .mn_bnr {
      .bnr_lft {
        padding-top: 70px;

        h1 {
          font-size: 34px;
          line-height: 40px;
        }

        p {
          font-size: 15px;
          line-height: 24px;
        }

        .btn_wrp {
          margin-top: 30px;
          button {
            font-size: 12px;
            padding: 12px 24px;
            min-width: 130px;
          }
        }
      }

      .bnr_ryt figure {
        height: 410px !important;
        width: 270px !important;
        right: 100px;
      }
    }
  }

  .crypto_wlt .mn_wlt {
    .crypto_lft {
      figure {
        height: 378px;
        width: 330px;
      }
    }

    .crypto_ryt {
      h2 {
        font-size: 26px;
        line-height: 36px;
      }

      p {
        margin: 6px 0 15px;
        line-height: 24px;
      }

      h4 {
        font-size: 18px;
      }
    }
  }

  .mn_crpto {
    padding-bottom: 285px;

    .pplr_crpto {
      h2 {
        font-size: 30px;
        line-height: 28px;
      }

      p {
        font-size: 15px;
      }
    }
  }

  .crpto_tdy {
    top: 150px;

    .css-1wx8fbe-MuiPaper-root {
      padding: 20px 40px;
      height: 540px;
    }
  }

  .crypto_bnfts {
    padding-top: 60px;

    .bnfts {
      h2 {
        font-size: 28px;
        line-height: 32px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
      }

      .mn_bnfts {
        li {
          width: 32%;

          figure {
            height: 80px;
            width: 80px;
          }
        }
      }
    }
  }

  .crpto_tlrd .tlrd_mn {
    .tlrd_lft {
      width: 45%;

      h2 {
        font-size: 28px;
        line-height: 36px;
      }

      p {
        line-height: 24px;
      }

      div {
        figure {
          width: 124px;
        }
      }
    }

    .tlrd_ryt figure {
      height: 400px;
      width: 300px;
    }
  }

  .why_blcktnx .mn_blctnx {
    .blktnx_wlt {
      h2 {
        font-size: 28px;
        line-height: 38px;
      }
    }
    .blktnx_ftrs .ftrs_crd p {
      font-size: 20px;
      line-height: 32px;
    }
  }
  .why_blcktnx section.blctnx_rtng {
    padding-bottom: 100px;

    .mn_rtng {
      .rtng_ryt {
        figure {
          height: 60px;
          width: 60px;
        }

        h2 {
          font-size: 24px;
          line-height: 28px;
        }

        p {
          line-height: 24px;
        }
      }

      .rtng_lft {
        width: 50%;

        .slick-list {
          height: 270px;

          figure {
            height: 270px;
          }

          .slick-current {
            height: 275px !important;

            figure {
              height: 315px !important;
            }
          }
        }
      }
    }
  }

  .strt_erng .mn_erng {
    h2 {
      font-size: 42px;
      line-height: 36px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
    }

    .btn_wrp {
      button {
        padding: 14px 26px;
      }
    }
    .cnct_frm {
      .cnct_lft {
        width: 47%;
        figure {
          margin-left: 0;
          width: 250px;
        }
        ul li:first-child {
          padding-bottom: 6px;
        }
      }
      .cnct_ryt {
        width: 47%;

        h4 {
          line-height: 40px;
        }
        .sbmt button.sbmt_btn {
          font-size: 14px !important;
          padding: 14px 40px !important;
        }
      }
    }
  }

  .ft_r .mn_ftr ul li.ftr_sec .logo {
    height: 60px;
    width: 180px;
  }
}

@media screen and (max-width: 767px) {
  .hm_bnr {
    background-image: url("../public/static/images/bnr_2.png");
    height: 320px;

    .mn_bnr {
      display: block;

      .bnr_lft {
        width: 100%;
        padding-top: 50px;
        text-align: center;
        .btn_wrp {
          border-radius: 4px;
          margin: 30px auto 0;
        }
      }

      .bnr_ryt {
        display: none;
      }
    }
  }

  .crypto_wlt {
    padding: 70px 0 70px;

    .mn_wlt {
      display: block;

      .crypto_lft {
        display: none;
        width: 100%;

        figure {
          height: 378px;
          width: 50%;
          margin: 0 auto;
          margin-bottom: 26px;
        }
      }

      .crypto_ryt {
        width: 100%;
        text-align: center;

        h4 {
          font-size: 20px;
        }
      }
    }
  }

  .crypto_bnfts .bnfts .mn_bnfts li {
    width: 100%;
    .d_blck {
      display: inline;
    }
  }

  .crpto_tlrd {
    padding-top: 70px;

    .tlrd_mn {
      display: block;

      .tlrd_lft {
        width: 100%;
        text-align: center;

        div {
          justify-content: space-between;

          width: 64%;
          margin: 0 auto;

          figure {
            width: 142px;
            height: 50px;
          }
        }
      }

      .tlrd_ryt {
        width: 100%;

        figure {
          height: 400px;
          width: 70%;
          margin: 50px auto 20px;
        }
      }
    }
  }

  .why_blcktnx {
    padding: 70px 0;
    .mn_blctnx .blktnx_ftrs .ftrs_crd p {
      font-size: 16px;
      line-height: 32px;
    }
  }

  section.blctnx_rtng {
    padding-bottom: 120px;

    .mn_rtng .rtng_lft {
      .slick-list {
        height: 310px;

        .slick-current {
          height: 365px !important;

          figure {
            height: 365px !important;
          }
        }
      }

      .arrow_btns {
        justify-content: space-evenly;

        div {
          margin-left: 0;
        }
      }
    }
  }

  .strt_erng {
    padding: 35px 0;

    .mn_erng {
      h2 {
        font-size: 38px;
        line-height: 34px;
      }
      .cnct_frm {
        .cnct_lft {
          display: none;
        }
        .cnct_ryt {
          width: 100%;
          h4 {
            text-align: center;
          }
          p {
            text-align: center;
          }
        }
      }
    }
  }

  .ft_r .mn_ftr ul {
    li.ftr_sec {
      width: 50%;

      .scl {
        .scl_icns {
          justify-content: center;
        }
      }
    }

    li {
      width: 50%;
      margin-bottom: 40px;
      text-align: center;
    }
  }

  .ft_r .mn_ftr ul :last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 600px) {
  .hm_bnr {
    height: 256px;

    .mn_bnr {
      .bnr_lft {
        padding-top: 20px;

        h1 {
          font-size: 30px;
          line-height: 38px;
        }

        button {
          font-size: 10px;
          padding: 14px 22px;
        }
      }
    }
  }

  .crpto_tlrd .tlrd_mn {
    .tlrd_lft div {
      width: 70%;

      figure {
        width: 120px;
      }
    }

    .tlrd_ryt {
      figure {
        width: 70%;
      }
    }
  }

  section.blctnx_rtng {
    padding-bottom: 70px;
    padding-top: 70px;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }

    .mn_rtng {
      display: block !important;

      .rtng_ryt {
        width: 100%;
        text-align: center;
        h2::after {
          display: none;
        }

        .rtng_str {
          justify-content: center;
        }
      }

      .rtng_lft {
        width: 100%;
        position: relative;
        margin-top: 30px;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }

        .slick-list {
          height: 430px;

          figure {
            height: 500px !important;
            // width: 50% !important;
          }

          .slick-current {
            figure {
              height: 500px !important;
              // width: 50% !important;
              margin: 0 auto;
            }
          }
        }

        .arrow_btns {
          display: none;
        }
      }
    }
  }
  .conta_iner.trms_use {
    h1 {
      font-size: 32px;
      padding-bottom: 20px;
    }
    p {
      font-size: 16px;
      line-height: 26px;
    }
    h4 {
      font-size: 22px;
    }
  }
  .conta_iner.pri_plcy {
    h1 {
      font-size: 32px;
      padding-bottom: 20px;
    }
    p {
      font-size: 16px;
      line-height: 26px;
      a {
        word-break: break-all;
      }
    }
    h4 {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 480px) {
  .hd_r {
    padding: 20px 0 10px;

    .conta_iner.hdr_2 div figure {
      width: 160px;
      margin-right: 20px;
    }
  }

  .hm_bnr {
    height: 170px;

    .mn_bnr .bnr_lft {
      padding-top: 6px;

      h1 {
        font-size: 26px;
        line-height: 30px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
      }

      .btn_wrp {
        margin-top: 4px;
        button {
          font-size: 8px;
          padding: 10px 20px;
          min-width: 100px;
        }
      }
    }
  }

  .crypto_wlt {
    padding: 70px 0 20px;

    .mn_wlt .crypto_ryt {
      h2 {
        font-size: 22px;
        line-height: 30px;
      }

      h2::after {
        height: 40px;
        width: 40px;
      }

      p {
        font-size: 13px;
      }

      h4 {
        font-size: 18px;
      }
    }
  }

  .mn_crpto .pplr_crpto {
    padding: 24px 0 285px;

    h2 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 13px;
    }
  }

  .crypto_bnfts .bnfts {
    h2 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      line-height: 20px;
      font-size: 13px;
    }
  }

  .crpto_tlrd {
    padding-top: 30px;

    .tlrd_mn {
      .tlrd_ryt {
        figure {
          width: 90%;
          margin: 20px auto 0px;
        }
      }

      .tlrd_lft {
        div {
          width: 100%;
          justify-content: center;

          figure {
            width: 130px;
            margin-right: 7px;
          }
        }

        h2 {
          font-size: 22px;
          line-height: 30px;
        }

        p {
          font-size: 13px;
          line-height: 22px;
        }
      }
    }
  }

  .why_blcktnx {
    padding: 30px 0;

    .mn_blctnx .blktnx_wlt {
      p {
        font-size: 13px;
        line-height: 22px;
      }

      h2 {
        font-size: 22px;
        line-height: 32px;
      }
    }
  }

  .strt_erng {
    padding: 20px 0;

    .mn_erng {
      p {
        font-size: 13px;
        line-height: 18px;
      }

      h2 {
        font-size: 30px;
        line-height: 30px;
      }

      .btn_wrp {
        margin-top: 10px;
        button {
          padding: 10px 14px;
          min-width: 120px;
        }
      }
      .cnct_frm .cnct_ryt .sbmt button.sbmt_btn {
        font-size: 12px !important;
        padding: 10px 30px !important;
        min-width: 100px;
      }
    }
  }

  .ft_r .mn_ftr ul {
    li.ftr_sec {
      width: 100%;

      .scl {
        .scl_icns {
          justify-content: center;
        }
      }
    }

    li {
      width: 100%;
    }
  }

  section.blctnx_rtng {
    padding-bottom: 70px;
    padding-top: 70px;

    .mn_rtng {
      display: block !important;

      .rtng_ryt {
        width: 100%;
        text-align: center;

        .rtng_str {
          justify-content: center;
        }
      }

      .rtng_lft {
        .slick-list {
          height: 440px;

          figure {
            height: 440px !important;
          }

          .slick-current {
            figure {
              height: 440px !important;
            }
          }
        }
      }
    }
  }
}
